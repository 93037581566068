export const FETCH_TIRES_REQUEST = 'FETCH_TIRES_REQUEST';
export const FETCH_TIRES_SUCCESS = 'FETCH_TIRES_SUCCESS';
export const FETCH_TIRES_FAILURE = 'FETCH_TIRES_FAILURE';
export const FETCH_TIRES_ERROR = 'FETCH_TIRES_ERROR';
export const UPDATE_TIRES = 'UPDATE_TIRES';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const UPDATE_USER = 'UPDATE_USER';

export const CHECK_LOGIN_REQUEST = 'CHECK_LOGIN_REQUEST';
export const CHECK_LOGIN_SUCCESS = 'CHECK_LOGIN_SUCCESS';
export const CHECK_LOGIN_FAILURE = 'CHECK_LOGIN_FAILURE';
export const CHECK_LOGIN_ERROR = 'CHECK_LOGIN_ERROR';
export const CHECK_USER_LOGIN = 'CHECK_USER_LOGIN';

export const OPEN_SUCCESS_INSERT_SNACKBAR = 'OPEN_SUCCESS_INSERT_SNACKBAR';
export const OPEN_SUCCESS_UPDATE_SNACKBAR = 'OPEN_SUCCESS_UPDATE_SNACKBAR';
export const OPEN_ERROR_SNACKBAR = 'OPEN_ERROR_SNACKBAR';
export const OPEN_WARNING_SNACKBAR = 'OPEN_WARNING_SNACKBAR';
export const OPEN_INFO_SNACKBAR = 'OPEN_INFO_SNACKBAR';
export const NOTIFY_SNACKBAR = 'NOTIFY_SNACKBAR';
